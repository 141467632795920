import React, { useContext } from 'react';
import PlansPage from 'src/px/PlansPage';
import { SessionContext } from 'src/context/SessionContext';

const ExplorerPlansView = ({}) => {
  const { signupUrl } = useContext(SessionContext);

  return <PlansPage flow="Explorer" onNext={() => window.open(signupUrl)} />;
};

export default ExplorerPlansView;
